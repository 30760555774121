import i18next from "../i18n";
import i18n from "../i18n";
import { Article } from "../models/blog/Article.model";

export function addArticalesTranslations(articles: Article[]) {
  // eslint-disable-next-line
  articles.map((article) => {
    if (!i18n.exists(`title.blog_${article.id}`)) {
      i18next.addResourceBundle("en", `blog_${article.id}`, {
        title: `${article.titleEn}`,
      });
      i18next.addResourceBundle("pl", `blog_${article.id}`, {
        title: `${article.title}`,
      });
    }

    if (!i18n.exists(`body.blog_${article.id}`)) {
      i18next.addResourceBundle("en", `blog_${article.id}`, {
        body: `${article.bodyEn}`,
      });
      i18next.addResourceBundle("pl", `blog_${article.id}`, {
        body: `${article.body}`,
      });
    }
  });
}
