import { useEffect, useState } from "react";
import { RootState } from "../../app/Store";
import Styles from "./UserProfileIcon.module.css";
import { getCurrentUser } from "../../services/UserApi";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { User } from "../../models/user/User.model";
import loginIcon from "../../assets/icons/user.svg";
import { createUserThumbnailURL } from "../../utils/UserHelper";

function UserProfileIcon() {
  const [userThumbnail, setUserThumbnail] = useState<string | null>(loginIcon);

  const userAccessToken = useSelector(
    (state: RootState) => state.userState.accessToken
  );

  useEffect(() => {
    if (userAccessToken !== null) {
      getCurrentUser(userAccessToken).then((data) => {
        const user: User = data;
        if (user.thumbnail !== undefined) {
          if (user.thumbnail !== null) {
            setUserThumbnail(user.thumbnail.name);
          } else {
            setUserThumbnail(null);
          }
        }
      });
    }
  }, [userAccessToken]);

  return (
    <div className={Styles.container}>
      <img
        className={Styles.img}
        src={
          userThumbnail !== null
            ? createUserThumbnailURL(userThumbnail)
            : loginIcon
        }
        alt="User Profile"
      />
    </div>
  );
}

export default UserProfileIcon;
