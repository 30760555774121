import Styles from "./DeliveryAndPayment.module.css";

import {
  initialUserAddress,
  UserAddress,
} from "../../models/user/UserAddress.model";
import { ICart } from "../../models/cart/Cart.model";
import { User } from "../../models/user/User.model";
import { IPartner } from "../../models/partner/IPartner.model";
import { Currency } from "../../models/cart/Currency.model";
import { RegionEnum } from "../../models/address/Region.model";
import { ITransaction } from "../../models/tpay/Transaction.model";

import { beginTransactionAPI } from "../../services/TPayAPI";
import { getPartnersAPI } from "../../services/PartnerAPI";
import { getShipmentPriceAPI, selectReferrerAPI } from "../../services/CartAPI";
import { getCurrentUser } from "../../services/UserApi";
import {
  addUserAddressAPI,
  getUserAddressAPI,
  patchUserAddressAPI,
} from "../../services/AddressAPI";

import SelectPaymentMethod from "./SelectPaymentMethod";
import {
  clearCart,
  setShipmentPriceToNull,
  setUserCart,
} from "../../features/cart/reducers/CartSlice";
import OrderValueSummary from "./OrderValueSummary";
import {
  isInvoiceAddressValid,
  isRegionValid,
  isShipmentAddressValid,
} from "../../utils/AddressHelpers";
import { isPaymentMethodValid } from "../../utils/CartHelpers";
import { RootState } from "../../app/Store";
import ShippingAddressForm from "./ShippingAddressForm";
import InvoiceAddressForm from "./InvoiceAddressForm";

import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactElement, useEffect, useState } from "react";
import React from "react";
import i18n from "../../i18n";
import { IShipmentAddress } from "../../models/address/ShipmentAddress.model";
import { ICountry } from "../../models/country/ICountry.model";
import { initialInvoiceAddress } from "../../models/address/InvoiceAddress.model";

export interface IErrorMessages {
  errorShipmentAddressMessage: String | null;
  errorInvoiceAddressMessage: String | null;
  errorPaymentMessage: String | null;
}

function DeliveryAndPayment() {
  const [userAddress, setUserAddress] =
    useState<UserAddress>(initialUserAddress);

  const [partnersList, setPartnersList] = useState<IPartner[]>([]);

  const [selectedPartner, setSelectedPartner] = useState<IPartner>();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
    useState<String>();

  const [isInvoiceRequested, setIsInvoiceRequested] = useState(false);
  const [errorMessages, setErrorMessages] = useState<IErrorMessages>(
    {} as IErrorMessages
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedPaymentMethodIdCallback = (dataFromChild: string) => {
    setSelectedPaymentMethodId(dataFromChild);
  };

  const selectedPartnerCallback = (dataFromChild: IPartner) => {
    setSelectedPartner(dataFromChild);
  };

  const selectedCountryCallback = (dataFromChild: ICountry | undefined) => {
    if (dataFromChild === undefined) return;

    const countryName: String =
      i18n.language === "pl"
        ? dataFromChild.polishName
        : dataFromChild.countryName;

    let newUserAddress = userAddress;
    newUserAddress.shippingAddress = {
      ...newUserAddress?.shippingAddress,
      // eslint-disable-next-line
      ["country"]: countryName,
      // eslint-disable-next-line
      ["region"]: dataFromChild?.region,
      // eslint-disable-next-line
      ["areaCode"]: dataFromChild?.diallingCode,
    } as IShipmentAddress;
    setUserAddress(newUserAddress);

    if (!isRegionValid(dataFromChild)) {
      setSelectedPaymentMethodId("999");
    } else {
      setSelectedPaymentMethodId("");
    }

    if (dataFromChild?.region !== null) {
      getShipmentPriceAPI(userAccessToken, dataFromChild?.region).then(
        (response) => {
          if (response?.ok) {
            response.json().then((data) => {
              const newCart: ICart = data;
              dispatch(setUserCart(newCart));
            });
          }
        }
      );
    }
  };

  const userAccessToken = useSelector(
    (state: RootState) => state.userState.accessToken
  );

  const userCart: ICart = useSelector(
    (state: RootState) => state.cartState.userCart
  );

  const userCurrency = useSelector(
    (state: RootState) => state.currencyState.userCurrency
  );

  useEffect(() => {
    if (userAccessToken === undefined || userAccessToken === null) {
      navigate("/");
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userCurrency.name === Currency.EUR) {
      setSelectedPaymentMethodId("999");
    }
    // eslint-disable-next-line
  }, [userCurrency]);

  useEffect(() => {
    getUserAddressAPI(userAccessToken)
      .then((response) => {
        if (response?.ok) {
          response.json().then((data) => {
            const userAddress: UserAddress = data;
            setUserAddress(userAddress);
          });
        }
      })
      .then(() => {
        getPartnersAPI(userAccessToken).then((response) => {
          if (response?.ok) {
            response.json().then((data) => {
              const partners: IPartner[] = data;
              setPartnersList(partners);
            });
          }
        });
      });
  }, [userAccessToken]);

  useEffect(() => {
    if (userAddress?.shippingAddress?.region === RegionEnum.OTHER) {
      dispatch(setShipmentPriceToNull);
    }

    if (
      userAddress?.shippingAddress?.region !== RegionEnum.POLAND &&
      userAddress?.shippingAddress?.region !== ""
    ) {
      setSelectedPaymentMethodId("999");
    }
    // eslint-disable-next-line
  }, [userAddress]);

  useEffect(() => {
    if (
      userAddress?.shippingAddress?.region === undefined ||
      userAddress?.shippingAddress?.region === RegionEnum.OTHER
    )
      return;

    getShipmentPriceAPI(
      userAccessToken,
      userAddress?.shippingAddress?.region
    ).then((response) => {
      if (response?.ok) {
        response.json().then((data) => {
          const newCart: ICart = data;
          dispatch(setUserCart(newCart));
        });
      }
    });
    // eslint-disable-next-line
  }, [userAddress]);

  useEffect(() => {
    if (selectedPartner?.id !== undefined)
      selectReferrerAPI(userAccessToken, selectedPartner?.id);
    // eslint-disable-next-line
  }, [selectedPartner]);

  const handleShipmentAddressInputChnage = (arg: {
    key: string;
    value: string;
  }) => {
    setUserAddress({
      id: userAddress.id,
      invoiceAddress: userAddress.invoiceAddress,
      shippingAddress: {
        ...userAddress.shippingAddress,
        [arg.key]: arg.value,
      },
    } as UserAddress);
  };

  const handleInvoiceAddressInputChnage = (arg: {
    key: string;
    value: string;
  }) => {
    setUserAddress({
      id: userAddress.id,
      shippingAddress: userAddress.shippingAddress,
      invoiceAddress: {
        ...userAddress.invoiceAddress,
        [arg.key]: arg.value,
      },
    } as UserAddress);
  };

  const onChangeAreaCode = (dataFromChild: string | undefined) => {
    setUserAddress({
      id: userAddress.id,
      invoiceAddress: userAddress.invoiceAddress,
      shippingAddress: {
        ...userAddress.shippingAddress,
        areaCode: dataFromChild,
      },
    } as UserAddress);
  };

  const handleGoToPayment = () => {
    const shipmentAddressValidationMessage: String | null =
      isShipmentAddressValid(userAddress?.shippingAddress);
    const paymentMethodValidationMessage: String | null = isPaymentMethodValid(
      selectedPaymentMethodId
    );

    let invoiceAddressValidationMessage: String | null = null;
    if (isInvoiceRequested) {
      invoiceAddressValidationMessage = isInvoiceAddressValid(
        userAddress?.invoiceAddress
      );
    }

    if (
      shipmentAddressValidationMessage !== null ||
      paymentMethodValidationMessage !== null ||
      invoiceAddressValidationMessage !== null
    ) {
      setErrorMessages({
        errorShipmentAddressMessage: shipmentAddressValidationMessage,
        errorInvoiceAddressMessage: invoiceAddressValidationMessage,
        errorPaymentMessage: paymentMethodValidationMessage,
      });
      return;
    } else {
      setErrorMessages({
        errorShipmentAddressMessage: null,
        errorInvoiceAddressMessage: null,
        errorPaymentMessage: null,
      });
    }

    setUserAddressForOrder()
      .then((response) => {
        if (response?.ok) {
          getCurrentUser(userAccessToken).then((data) => {
            const user: User = data;
            let newUserAddress = userAddress;
            if (
              newUserAddress?.id !== undefined &&
              user.userAddress !== undefined &&
              user.userAddress !== null
            ) {
              newUserAddress.id = user.userAddress.id;
            }
            setUserAddress(newUserAddress);
          });
        } else {
          if (errorMessages !== undefined)
            setErrorMessages({
              ...errorMessages,
              errorPaymentMessage: t(
                `deliveryAndPaymentAndPage.SOMETHING_WENT_WRONG`
              ),
            });
          return;
        }
      })
      .then(() => {
        beginTransacion().then(async (respone) => {
          if (respone?.ok) {
            const tpayURL = await respone.text();
            if (tpayURL !== "" && tpayURL !== null && tpayURL !== undefined) {
              dispatch(clearCart());
              if (i18n.language === "pl") {
                window.location.replace(tpayURL);
              } else {
                navigate(tpayURL);
              }
            } else {
              if (errorMessages !== undefined) {
                setErrorMessages({
                  ...errorMessages,
                  errorPaymentMessage: t(
                    `deliveryAndPaymentAndPage.SOMETHING_WENT_WRONG`
                  ),
                });
                return;
              }
            }
          } else {
            if (errorMessages !== undefined) {
              setErrorMessages({
                ...errorMessages,
                errorPaymentMessage: t(
                  `deliveryAndPaymentAndPage.SOMETHING_WENT_WRONG`
                ),
              });
              return;
            }
          }
        });
      });
  };

  const setUserAddressForOrder = async () => {
    if (userAddress?.shippingAddress === undefined) return;

    if (userAddress?.id !== undefined) {
      return await patchUserAddressAPI(userAccessToken, userAddress);
    } else {
      return await addUserAddressAPI(
        userAccessToken,
        userAddress.shippingAddress,
        userAddress?.invoiceAddress
      );
    }
  };

  const beginTransacion = async () => {
    if (selectedPaymentMethodId === undefined) return;

    const transaction: ITransaction = {
      paymentGroup: selectedPaymentMethodId,
      useInvoiceAddress: isInvoiceRequested.toString(),
      language: i18n.language,
      currency: userCurrency,
    };
    return await beginTransactionAPI(userAccessToken, transaction);
  };

  const renderShipmentValue = (): ReactElement => {
    let currencyValue: String = "";
    let shipmentValue: String = "";
    if (
      userAddress?.shippingAddress === undefined ||
      userAddress?.shippingAddress?.country === ""
    ) {
      shipmentValue = t(`deliveryAndPaymentAndPage.SELECT_COUTRY`);
    } else {
      if (
        userCart.shipmentPrice === null ||
        userCart.shipmentPriceEur === null
      ) {
        shipmentValue = t(`deliveryAndPaymentAndPage.SHIPMENT_NOT_AVAIABLE`);
      } else {
        currencyValue = userCurrency.name;
        if (userCurrency.name === Currency.PLN) {
          shipmentValue = userCart.shipmentPrice;
        } else {
          shipmentValue = userCart.shipmentPriceEur;
        }
      }
    }

    return (
      <React.Fragment>
        {t(`deliveryAndPaymentAndPage.DELIVERY_PRICE`)}
        {": "}
        {shipmentValue} {currencyValue}
      </React.Fragment>
    );
  };

  const handleInvoiceRequested = () => {
    if (
      userAddress.invoiceAddress === null ||
      userAddress.invoiceAddress === undefined
    ) {
      setUserAddress({
        id: userAddress.id,
        shippingAddress: userAddress.shippingAddress,
        invoiceAddress: initialInvoiceAddress,
      });
    }
    setIsInvoiceRequested(!isInvoiceRequested);
  };

  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <hr className={Styles.divider} />
      <h2 className={Styles.title}>{t(`deliveryAndPaymentAndPage.WELCOME`)}</h2>
      <div className={Styles.featuresContainer}>
        <div className={Styles.addressContainer}>
          <form className={Styles.form} action="">
            <h3 className={Styles.subtitle}>
              {t(`deliveryAndPaymentAndPage.DELIVERY_OPTION`)}
            </h3>
            <div className={Styles.formContainer}>
              <div className={Styles.radioButtonContainer}>
                <input
                  className={Styles.radio}
                  type="radio"
                  id="delivery"
                  name="delivery"
                  value="deliver"
                  onChange={(e) => {}}
                  checked
                />
                <label className={Styles.radioLablel} htmlFor="delivery">
                  {t(`deliveryAndPaymentAndPage.CURIER_DELIVERY`)}
                </label>
              </div>
              <p className={Styles.deliveryText}>{renderShipmentValue()}</p>
            </div>
          </form>
        </div>
        <div className={Styles.addressContainer}>
          <ShippingAddressForm
            onInputChnage={handleShipmentAddressInputChnage}
            defaultInput={userAddress?.shippingAddress}
            errorMessage={errorMessages?.errorShipmentAddressMessage}
            partners={partnersList}
            onSelectPartner={selectedPartnerCallback}
            onSelectCountry={selectedCountryCallback}
            onChangeAreaCode={onChangeAreaCode}
          />

          <fieldset className={Styles.checkboxContainer}>
            <input
              className={Styles.checkbox}
              type="checkbox"
              id="invoiceAddress"
              onChange={handleInvoiceRequested}
            />
            <label className={Styles.checkboxLabel} htmlFor="invoiceAddress">
              {t(`deliveryAndPaymentAndPage.INVOICE_QUESTION`)}
            </label>
          </fieldset>
          {isInvoiceRequested && (
            <div>
              <InvoiceAddressForm
                onInputChnage={handleInvoiceAddressInputChnage}
                defaultInput={userAddress?.invoiceAddress}
                errorMessage={errorMessages?.errorInvoiceAddressMessage}
              />
            </div>
          )}
        </div>
        {userAddress.shippingAddress?.region !== "" &&
          userAddress.shippingAddress?.region !== RegionEnum.OTHER &&
          userCurrency.name === Currency.PLN && (
            <SelectPaymentMethod
              selectedPaymentMethod={selectedPaymentMethodIdCallback}
            />
          )}
        <OrderValueSummary
          errorInvoiceAddressMessage={errorMessages?.errorInvoiceAddressMessage}
          errorShippingAddressMessage={
            errorMessages?.errorShipmentAddressMessage
          }
          errorPaymentMessage={errorMessages?.errorPaymentMessage}
          userCart={userCart}
          handleGoToPayment={handleGoToPayment}
          userCurrency={userCurrency}
          shippmentAddress={userAddress?.shippingAddress}
        />
      </div>
    </motion.div>
  );
}

export default DeliveryAndPayment;
