import HeroSection from "./HeroSection";
import TextSection from "./TextSection";
// import CommentSection from "./CommentSection";

import Styles from "./Article.module.css";
import { useSearchParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { Article as ArticleModel } from "../../models/blog/Article.model";
import { getArticleById } from "../../services/ArticleAPI";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { addArticalesTranslations } from "../../utils/BlogHelpers";

function Article() {
  const [searchparams] = useSearchParams();
  const [article, setArticle] = useState<ArticleModel>();
  const { t } = useTranslation();

  const imageAPI: string = `${process.env.REACT_APP_TRAINPORT_BACKEND_API_URL}/image/`;

  useEffect(() => {
    const articleId = searchparams.get("id");
    getArticleById(articleId).then((item) => {
      addArticalesTranslations(new Array<ArticleModel>(item));
      setArticle(item);
    });
  }, [searchparams]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      <HeroSection image={imageAPI + article?.image?.name} />
      {article !== undefined && (
        <TextSection
          key={article.id}
          title={t(`blog_${article.id}:title`)}
          author={article.author}
          datePublished={article.datePublished}
          text={t(`blog_${article.id}:body`)}
        />
      )}
    </motion.div>
  );
}

export default Article;
