import { fetchData, fetchPrivateData } from "../utils/API";
import { User } from "../models/user/User.model";
import { ActivityLevelType } from "../models/user/ActivityLevelType.model";
import { GoalType } from "../models/user/GoalType.model";
import { Order } from "../models/order/Order.model";

const paths = {
  users: "/users",
  login: "/users/login",
  currentUser: "/users/current-user",
  uploadProfileImage: "/users/upload-profile-image",
  resetPassword: "/users/reset-password",
  activityLevel: "/activity-level-type",
  goal: "/goal-type",
  userOrder: "/order",
};

export async function loginAPI(username: string, password: string) {
  const response = await fetchData(paths.login, {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
      captchaResponseToken:
        "03ANYolqsM0mOF7C2NJcVjfYxQ0xy7FQdcrKq_q3NPpTmXUgPYPKlUDs8H3mNJkzlRNUAyE-XTHFRqN__Qhnju978sGxE29cuetpDmxG418NjtiC4vkjJVp6tsy8kdVpvajzLBeFvXaXIK-_6k7F-QSMrg-Y4XUPh_qds_bW6m0_DDwcXZDy1kyMkqZ4TmAyzkb63mBrCNlRwM2mANsCrMinKrCK9HuY0_7w7YTzOL2x09TJwM12FCedS0q5nUfeLWRZ96N57DMc6hLbFIvjdBF2cfj628ylwPQS6roLVFWhs6SCJdS_kwv56v9yKeQ0Hiq8bm2DGGYa4gO1Hz1Gdw2smEPCas0CNDYvOQALERZS7Slp1nifZhoObNkl1NtPtHttmiW6bHLzoL9IHe_3yORwSw1W678sRzZF7mlJZayYVsCGo3yuOAVWYk_zwXnGUDpMqBuhTe5gnExdF5MrVyEfoD2Jb4WGwgf1sV7HJEeiKIa0Kr7M_iuGcOH9fTAKyJ_IJNTRaiX4D7",
    }),
  });

  return response;
}

export async function createUserAPI(emailAddress: string, password: string) {
  const user: { [key: string]: string } = {
    emailAddress,
    password,
  };

  const response = await fetchData(paths.users, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  });

  return response;
}

export async function deleteUserAPI(userAccessToken: string, userId: string) {
  const response = await fetchPrivateData(
    paths.users + "/" + userId,
    userAccessToken,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }
  );

  return response;
}

export async function getAccessToken(username: string, password: string) {
  const response = await fetchData(paths.login, {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
      captchaResponseToken:
        "03ANYolqsM0mOF7C2NJcVjfYxQ0xy7FQdcrKq_q3NPpTmXUgPYPKlUDs8H3mNJkzlRNUAyE-XTHFRqN__Qhnju978sGxE29cuetpDmxG418NjtiC4vkjJVp6tsy8kdVpvajzLBeFvXaXIK-_6k7F-QSMrg-Y4XUPh_qds_bW6m0_DDwcXZDy1kyMkqZ4TmAyzkb63mBrCNlRwM2mANsCrMinKrCK9HuY0_7w7YTzOL2x09TJwM12FCedS0q5nUfeLWRZ96N57DMc6hLbFIvjdBF2cfj628ylwPQS6roLVFWhs6SCJdS_kwv56v9yKeQ0Hiq8bm2DGGYa4gO1Hz1Gdw2smEPCas0CNDYvOQALERZS7Slp1nifZhoObNkl1NtPtHttmiW6bHLzoL9IHe_3yORwSw1W678sRzZF7mlJZayYVsCGo3yuOAVWYk_zwXnGUDpMqBuhTe5gnExdF5MrVyEfoD2Jb4WGwgf1sV7HJEeiKIa0Kr7M_iuGcOH9fTAKyJ_IJNTRaiX4D7",
    }),
  });

  if (response === undefined || !response.ok) {
    throw new Error("Login failed");
  }

  const token = response.headers.get("access_token");
  return token;
}

export async function resetPassword(
  username: string,
  password: string,
  token: string
) {
  const response = await fetchData(paths.resetPassword, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify({ username, password, token }),
  });

  return response?.status;
}

export async function resetPasswordEmailAPI(username: string) {
  const response = await fetchData(paths.resetPassword, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify({ username }),
  });

  return response;
}

export async function getCurrentUser(token: string | null): Promise<User> {
  const response = await fetchData(paths.currentUser, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response === undefined || !response.ok) {
    throw new Error("Verify token failed");
  }

  const data: User = await response.json();
  return data;
}

export async function activateAccount(registrationConfirmationToken: string) {
  const response = await fetchData("/users/activate-account", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token: registrationConfirmationToken,
    }),
  });

  return response?.status;
}

export async function patchUser(user: User, token: string | null) {
  const response = await fetchData(paths.users, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(user),
  });

  if (response === undefined || !response.ok) {
    const errorResponse = await response?.json();
    console.error("Request failed:", errorResponse);
    throw new Error("Patch user failed");
  }
}

export async function uploadProfileImage(
  image: FormData,
  token: string | null
) {
  const response = await fetchData(paths.uploadProfileImage, {
    method: "PATCH",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
    body: image,
  });

  if (response === undefined || !response.ok) {
    const errorResponse = await response?.json();
    console.error("Request failed:", errorResponse);
    throw new Error("Uploading profile image failed");
  }
}

export async function getAllActivityLevelTypes(token: string | null) {
  const response = await fetchPrivateData(paths.activityLevel, token);

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from: " + paths.activityLevel);
  }
  const data: ActivityLevelType[] = await response?.json();
  return data;
}

export async function getAllGoalTypes(token: string) {
  const response = await fetchPrivateData(paths.goal, token);

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from: " + paths.goal);
  }

  const data: GoalType[] = await response?.json();
  return data;
}

export async function getUserOderAPI(token: string | null): Promise<Order[]> {
  const response = await fetchData(paths.userOrder, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response === undefined || !response.ok) {
    throw new Error("Verify token failed");
  }

  const json = await response.json();
  const data = json.content;
  return data;
}
